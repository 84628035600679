import * as tp from '../actions/ActionTypes/types'

    const initialState = {
        isFetching:true,
        package_response: []
    };

  export default (state = initialState, action) => {
    switch (action.type) {
      case tp.PACKAGE_ARRAY_FETCING:
          return {
            ...state,
            isFetching:true,
            package_response: action.payload
          };
        case tp.PACKAGE_ARRAY_FETCHED:
          return {
            ...state,
            isFetching:false,
            package_response: action.payload
          };
        default:
          return state;
    }
  };
    