import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom'
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));


// Forgot Password
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword/forgotPassword"));
const ResetPassword = React.lazy(() => import("./views/ForgotPassword/forgotPasswordLink"));

class App extends Component { 

  render() {
    return (
      <div>
          {/* <Router> */}
          {/* <SweetAlertComp/> */}
        <Router basename={process.env.REACT_APP_ENV == "development" ? '/geopay-admin' : ''}>
            <React.Suspense fallback={loading()}>
                <Switch>
                    <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />  
                    <Route exact path="/forgotPassword" name="forgotPassword" render={props => <ForgotPassword {...props}/>} />
                    <Route exact path="/resetPassword/:_email/:_token" name="resetPassword" render={props => <ResetPassword {...props}/>} />
                    <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                </Switch>
            </React.Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
