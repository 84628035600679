import * as ts from '../actions/ActionTypes/types'

const initialState = {
    dashboard_count : [],
    isFetching : true,
};

const dashbordCount = (state = initialState, action) => {
  switch (action.type) {
    case ts.DASHBOARD_COUNT: {
        return {
            ...state,
            dashboard_count: action.payload,
            isFetching : false
        };
    }
    default:
      return state;
  }
};

export default dashbordCount;
