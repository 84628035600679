import * as type from '../actions/ActionTypes/types'

const initialState = {
    userListResponse : '',
    isFetching : true
};

const getReportedUsers = (state = initialState, action) => {
  switch (action.type) {
    case type.REPORTED_USER_LIST: {
        return {
            userListResponse : action.payload,
            isFetching: false,
        };
    }
    case type.REPORTED_LIST_NOT_FOUND: {
      return {
          userListResponse : action.payload,
          isFetching: false,
      };
  }
    default:
      return state;
  }
};

export default getReportedUsers;
