export const SWAL_SUCCESS = 'SWAL_SUCCESS'

const initialState = {
    swalState : false
};

const swalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWAL_SUCCESS: {
        return {
            ...state,
            swalState : true
        };
    }
    default:
      return state;
  }
};

export default swalReducer;
