import * as ts from '../actions/ActionTypes/types'

const initialState = {
    category_list : [],
    isFetching : true,
};

const userDelete = (state = initialState, action) => {
  switch (action.type) {
    case ts.GET_CATEGORIES: {
        return {
            ...state,
            category_list: action.payload,
            isFetching : false
        };
    }
    case ts.DELETE_CATEGORIES : 
        return {
            ...state
        }  
    default:
      return state;
  }
};

export default userDelete;
