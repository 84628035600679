import { combineReducers } from "redux";
import questionReducer from './questiondelete'
import swalRediucer from './helpers/swalReducer'
import getDetailsOfUser from './questionDataById'
import dashbordCount from './dashboardReducer'

import getAllPackages from './getAllPackages'

// Categories Reducer
import categoriesReducer from './categories'

// User reducer
import userDelete from './userDelete'

// Reported Event
import getReportedEvents from './reportAction'
import getReportedUsers from './reportedUserList'

// Sub Plans
import {userSubPlan} from './getSubPlan'

// Block
import feedBlock from './feedBlock'

const rootReducer = combineReducers({
      questionReducer,
      swalRediucer,
      getDetailsOfUser,
      userDelete,
      categoriesReducer,
      getReportedEvents,
      getReportedUsers,
      feedBlock,
      dashbordCount,
      userSubPlan,
      getAllPackages
});

export default rootReducer;
