import * as ts from '../actions/ActionTypes/types'

const initialState = {
    UserDeleted : false
};

const userDelete = (state = initialState, action) => {
  switch (action.type) {
    case ts.DELETE_USER: {
        return {
            ...state,
            UserDeleted: true,
        };
    }
    default:
      return state;
  }
};

export default userDelete;
