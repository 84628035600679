import * as type from '../actions/ActionTypes/types'


const initialState = {
    UserResponse : {},
    clientResponse : {},
    isFetching : true
};

const getDetailsById = (state = initialState, action) => {
  console.log(action.payload)
  switch (action.type) {
    case type.GET_LISTING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case type.GET_LISTING_SUCCESS: {
        return {
            ...state,
            isFetching: false,
            UserResponse: action.payload,
        };
    }
    case type.GET_LISTING_FAILED: {
        return {
              ...state,
              UserResponse: action.payload
        };
    }
    default:
      return state;
  }
};

export default getDetailsById;
