import * as ts from '../actions/ActionTypes/types'

const initialState = {
    feed_block_status : false,
    isFetching : true,
};

const feedBlock = (state = initialState, action) => {
  switch (action.type) {
    case ts.BLOCK_FEED: {
        return {
            ...state,
            feed_block_status: action.payload,
            isFetching : false
        };
    }
    default:
    return state;
  }
};

export default feedBlock;
