import * as type from '../actions/ActionTypes/types'


const initialState = {
    questionArray : [],
    isFetching : true,
    
     
};

const DeleteQuestion = (state = initialState, action) => {

  switch (action.type) {
    case type.DELETING_QUESTION: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case type.DELETED: {
      console.log({...state})
        return {
            ...state,
            isFetching: false,
            questionArray: action.payload,
        };
    }
    case type.DELETED_FAILED: {
        return {
              ...state,
              errorMessage: action.payload
        };
    }
    default:
      return state;
  }
};

export default DeleteQuestion;
