import * as type from '../actions/ActionTypes/types'

const initialState = {
    listRespone : '',
    isFetching : true
};

const getReportedEvents = (state = initialState, action) => {
  switch (action.type) {
    case type.REPORTED_ACTION_LIST: {
        return {
            listRespone : action.payload,
            isFetching: false,
        };
    }
    default:
      return state;
  }
};

export default getReportedEvents;
