// Delete 
export const DELETING_QUESTION = 'DELETING_QUESTION'
export const DELETED = 'DELETED'
export const DELETED_FAILED = 'DELETED_FAILED'

// GET User Subscription plan

export const USER_CURRENT_PLAN = 'USER_CURRENT_PLAN'

// Reported User 
export const REPORTED_ACTION_LIST = 'REPORTED_ACTION_LIST'
export const REPORTED_USER_LIST = 'REPORTED_USER_LIST'
export const REPORTED_LIST_NOT_FOUND = 'REPORTED_LIST_NOT_FOUND'

// Get LIst
export const GET_LISTING = 'GET_LISTING'
export const GET_LISTING_SUCCESS = 'GET_LISTING_SUCCESS'
export const GET_LISTING_FAILED = 'GET_LISTING_FAILED'


// Categories
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const DELETE_CATEGORIES  = 'DELETE_CATEGORIES'

// User Deleting
export const DELETE_USER = 'DELETE_USER'

// User Deleting
export const BLOCK_FEED = 'BLOCK_FEED'

// Dashboard 
export const DASHBOARD_COUNT = 'DASHBOARD_COUNT'

// GET All Packages 
export const PACKAGE_ARRAY_FETCING = 'PACKAGE_ARRAY_FETCING'
export const PACKAGE_ARRAY_FETCHED = 'PACKAGE_ARRAY_FETCHED'

