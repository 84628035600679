import * as ts from '../actions/ActionTypes/types'

const initialState = {
    isFetching : true,
    userSubPlanObject  : {}
};

export const userSubPlan = (state = initialState, action) => {
  switch (action.type) {
    case ts.USER_CURRENT_PLAN: {
      console.log("reduer", action.payload)
        return {
              isFetching : false,
              userSubPlanObject: action.payload,
        };
    }
    default:
      return state;
  }
};
